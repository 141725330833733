<template>
    <div class="viewContainer">
        <div class="container"></div>
        <Banner />
        <Details />
        <TrustedBy />
        <Features />
        <HowItWork />
        <Testimonial />
        <Pricing />
        <FAQ />
    </div>
</template>

<script>
import Banner from '@/components/academia/Banner.vue'
import Details from '@/components/academia/Details.vue'
import TrustedBy from '../components/academia/TrustedBy.vue'
import Features from '@/components/academia/Features.vue'
import HowItWork from '@/components/academia/HowItWork.vue'
import Testimonial from '../components/academia/Testimonial.vue'
import Pricing from '@/components/academia/Pricing.vue'
import FAQ from '@/components/academia/FAQ.vue'

import loadScriptInBody from '@/assets/javascript/loadScriptInBody'

export default {
    name: 'educationInstitutions',
    components: {
        Banner,
        Details,
        TrustedBy,
        Features,
        HowItWork,
        Testimonial,
        Pricing,
        FAQ
    },
    data() {
        return {

        }
    },
    methods: {
        loadSnitcher() {
            if (process.env.NODE_ENV === 'development') {
                return
            }
            loadScriptInBody.loadScriptInBody('https://snid.snitcher.com/8422298.js')
                .then(() => {
                    window.snid('verify', '8422298')
                })
                .catch(() => {
                })

            loadScriptInBody.loadScriptInBody('https://a.omappapi.com/app/js/api.min.js', [{ name: 'user', value: '269893' }, { name: 'account', value: '287518' }])
                .then(() => {
                })
                .catch(() => {
                })
        },
        unloadSnitcher() {
            if (process.env.NODE_ENV === 'development') {
                return
            }

            loadScriptInBody.unloadScriptInBody('https://snid.snitcher.com/8422298.js')
            loadScriptInBody.unloadScriptInBody('https://a.omappapi.com/app/js/api.min.js')
        }
    },
    mounted() {
        this.loadSnitcher()
    },
    beforeDestroy() {
        this.unloadSnitcher()
    },
}
</script>

<style lang="scss" scoped>
.viewContainer {
    background-color: #f8f9f8;
}

.container {
    width: 100%;

    @media (min-width: 640px) {

        max-width: 640px;

    }

    @media (min-width: 768px) {

        max-width: 768px;

    }

    @media (min-width: 1024px) {

        max-width: 1024px;

    }

    @media (min-width: 1280px) {

        max-width: 1280px;

    }

    @media (min-width: 1536px) {

        max-width: 1536px;

    }
}
</style>
