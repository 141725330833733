<template>
    <section>
        <div class=" container">
            <div class="statistics-container">
                <div class="statistic-item">
                    <h2 class="statistic-title">
                        1 Million
                    </h2>
                    <p class="statistic-description">
                        Monthly Users
                    </p>
                </div>
                <div class="statistic-item">
                    <h2 class="statistic-title">
                        10K+
                    </h2>
                    <p class="statistic-description">
                        Institutional Signups
                    </p>
                </div>
                <div class="statistic-item">
                    <h2 class="statistic-title">
                        76
                    </h2>
                    <p class="statistic-description">
                        Programming Languages
                    </p>
                </div>
                <div class="statistic-item">
                    <h2 class="statistic-title">
                        2
                    </h2>
                    <p class="statistic-description">
                        Databases
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'educationInstitutionsDetail',
    components: {},
    data() {
        return {};
    },
    methods: {},
};
</script>


<style scoped>
.statistics-section {}

.container {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.statistics-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: -1rem;
    margin-right: -1rem;

    @media (min-width: 1024px) {
        flex-direction: row;

    }
}

.statistic-item {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
        width: 33.333333%;
    }

    @media (min-width: 1024px) {
        width: 25%;
        margin-bottom: 0px;
    }
}

.statistic-title {
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: #fd5a0d;

    @media (min-width: 768px) {
        font-size: 3rem;
    }
}

.statistic-description {
    font-size: 1.125rem;
    color: #64748b;
    font-weight: 500;

    @media (min-width: 768px) {
        font-size: 1.25rem;
    }
}
</style>