export default {
  loadScriptInBody (src, data) { // eslint-disable-line no-param-reassign
    return new Promise(function (resolve, reject) {
      if (document.querySelector('script[src="' + src + '"]')) {
        resolve()
        return
      }

      const el = document.createElement('script')

      el.type = 'text/javascript'
      el.async = true
      el.src = src

      if (data) {
        for (var n in data) {
          el.dataset[data[n].name] = data[n].value
        }
      }

      el.addEventListener('load', resolve)
      el.addEventListener('error', reject)
      el.addEventListener('abort', reject)

      document.body.appendChild(el)
    })
  },
  unloadScriptInBody (src) { // eslint-disable-line no-param-reassign
    return new Promise(function (resolve, reject) {
      const el = document.querySelector('script[src="' + src + '"]')

      if (!el) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject()
        return
      }

      document.body.removeChild(el)

      resolve()
    })
  }
}
