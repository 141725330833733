<template>
    <section class="section-container">
        <div class="container">
            <div class="flex-container">

                <h2 class="header">Trusted by the top education providers in this industry</h2>
                <div class="image-carousel">
                    <div class="carousel-container">
                        <div class="carousel">
                            <div class="image-wrapper" v-for="(image, index) in visibleImages" :key="index">
                                <img class="img" :src="image" alt="Carousel Image" />
                            </div>
                        </div>
                    </div>
                    <!-- <div class="carousel-navigation">
            <button class="prev-button" @click="prevImage">&#60;</button>
            <button class="next-button" @click="nextImage">&#62;</button>
        </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            images: [
                require('@/assets/images/educationInstitution/schools/school1.webp'),
                require('@/assets/images/educationInstitution/schools/school2.webp'),
                require('@/assets/images/educationInstitution/schools/school3.webp'),
                require('@/assets/images/educationInstitution/schools/school4.webp'),
                require('@/assets/images/educationInstitution/schools/school5.webp'),
                require('@/assets/images/educationInstitution/schools/school6.webp'),
                require('@/assets/images/educationInstitution/schools/school7.webp'),
                require('@/assets/images/educationInstitution/schools/school8.webp'),
                require('@/assets/images/educationInstitution/schools/school9.webp'),
            ],
            currentIndex: 0,
            itemsPerRow: 5,
            rotationInterval: null,
        };
    },
    computed: {
        visibleImages() {
            const start = this.currentIndex;
            const end = start + this.itemsPerRow;
            return this.images.concat(this.images).slice(start, end);
        },
    },
    mounted() {
        this.startRotation();
    },
    methods: {
        startRotation() {
            this.rotationInterval = setInterval(() => {
                this.nextImage();
            }, 3000);
        },
        resetRotation() {
            clearInterval(this.rotationInterval);
            this.startRotation();
        },
        nextImage() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
            this.resetRotation();
        },
        prevImage() {
            this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
            this.resetRotation();
        },
    },
    onDestroy() {
        clearInterval(this.rotationInterval);
    },
};
</script>
<style scoped>
.section-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 500;
    line-height: 1.5rem;
    color: #a9a9a9;
}

.image-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.carousel-container {
    position: relative;
}

.carousel {
    display: flex;
    overflow-x: hidden;
}

.image-wrapper {
    flex: 0 0 calc(100% / 5.25);
    margin-right: 10px;
    max-height: 150px;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #f0eeee;
}

.carousel-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.prev-button,
.next-button {
    width: 30px;
    height: 30px;
    font-size: 18px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.prev-button {
    margin-right: 10px;
}

.next-button {
    margin-left: 10px;
}
</style>
