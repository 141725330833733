<template>
    <section class="section-container">
        <div class="section-content">
            <div class="container">
                <div class="flex-container">
                    <div class="content-column">
                        <h1 class="section-title">
                            Plugins to make coding education efficient
                        </h1>
                        <p class="section-description">
                            JDoodle is a great choice for Universities, Colleges &amp; Schools looking to seamlessly
                            integrate a powerful, cost-effective &amp; easy-to-use online compiler into their LMS.
                        </p>
                        <div class="button-group">
                            <div class="button-wrapper">
                                <a class="btn demo-button" href="https://app.monstercampaigns.com/c/eelbkirqhtobo1lclzwx/"
                                    rel="noopener noreferrer">
                                    Request a Demo
                                </a>
                            </div>
                            <div class="button-wrapper">
                                <button v-if="!$store.state.isUserLoggedIn" class="btn signup-button"
                                    @click="onClickSignup">
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="image-column">
                        <div class="image-container">
                            <img class="circle-image"
                                src="../../assets/images/educationInstitution/banner/circle2-yellow.svg" alt="">
                            <img class="dots-image" src="../../assets/images/educationInstitution/banner/dots3-blue.svg"
                                alt="">
                            <img class="main-image" src="../../assets/images/educationInstitution/banner/Plugins-demo-1.png"
                                alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../assets/javascript/constants'
export default {
    name: 'educationInstitutionsBanner',
    components: {},
    data() {
        return {};
    },
    methods: {
        onClickSignup() {
            eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Register.vue'))
        }
    },
};
</script>

<style scoped>
.section-container {
    position: relative;
    overflow: hidden;
    background-position: center;

}

.section-content {

    padding-top: 5rem;
    padding-bottom: 5em;

    @media (min-width: 768px) {
        padding-top: 7rem;
        padding-bottom: 7rem;
    }
}

.container {

    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.flex-container {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: -1rem;
    margin-right: -1rem;
    gap: 4rem;

    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
        gap: 0rem;
    }
}

.content-column {

    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 4rem;

    @media (min-width: 1024px) {
        width: 50%;
        margin-bottom: 0px;
    }
}

.section-title {
    margin-bottom: 1.5rem;
    font-size: 1.875rem;
    font-weight: 500;
    line-height: 1.25;
    font-weight: 700;
    letter-spacing: -1px;

    @media (min-width: 768px) {
        font-size: 3rem;
    }

    @media (min-width: 1024px) {
        font-size: 3.75rem;
    }
}

.section-description {
    margin-bottom: 2rem;
    font-size: 1.125rem;
    color: #64748b;
    font-weight: 500;

    @media (min-width: 768px) {
        font-size: 1.25rem;
    }

}

.button-group {
    display: flex;
    flex-wrap: wrap;
}

.button-wrapper {
    width: 100%;

    @media (min-width: 1024px) {
        width: auto;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 1rem;
    }
}

.btn {
    display: inline-block;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    font-size: 1rem;
    width: 100%;
    line-height: 1rem;
    font-weight: 500;
    text-align: center;
    border-width: 1px;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    @media (min-width: 1024px) {
        width: auto;
        font-size: 1.125rem;
    }
}

.demo-button {
    color: white;
    background-color: #fd5a0d;
    border: 1px solid #e5e7eb;
}

.demo-button:hover {
    background-color: #fd590dde;
}

.signup-button {
    color: #374151;
    background-color: white;
    border: 1px solid #e5e7eb;
}

.signup-button:hover {
    background-color: #f4f5f7;
}

.image-column {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 1024px) {
        width: 50%;
    }
}

.image-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;

    @media (min-width: 768px) {
        margin-right: 0px;
    }
}

.circle-image {
    position: absolute;
    z-index: 10;
    left: -3.5rem;
    top: -3rem;
    width: 7rem;
}

.dots-image {
    position: absolute;
    z-index: 10;
    bottom: -2rem;
    right: -1.75rem;
    width: 7rem;
    width: 28px;

    @media (min-width: 768px) {
        width: auto;
    }
}

.main-image {
    position: relative;
    border-radius: 1.5rem
}
</style>