<template>
    <section class="section-container">
        <div class="container">
            <div class="flex-container">
                <div class="image-column">
                    <div class="image-container">
                        <img class="circle-image"
                            src="../../assets//images/educationInstitution/howItWork/circle2-yellow.svg" alt="">
                        <img class="dots-image" src="../../assets//images/educationInstitution/howItWork/dots3-blue.svg"
                            alt="">
                        <div class="html-container">
                            <div class="menu">
                                <span class="menu_Items" style="background-color:red"></span>
                                <span class="menu_Items" style="background-color:yellow"></span>
                                <span class="menu_Items" style="background-color:greenyellow"></span>
                            </div>
                            <div v-for="(text, i) in htmlText" :key="i" class="code-line">
                                <span v-for="{ _, j } in text.tab" :key="j" class="tab" />

                                <span v-text="text.text"></span>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="content-container">
                    <span class="badge">How it works</span>
                    <h2 class="title">3 simple steps to integrate plugins into your teaching platform</h2>
                    <div class="step-container">
                        <div class="step">
                            <div class="step-icon">1</div>
                            <h3 class="step-title">Copy the code</h3>
                        </div>
                        <div class="step">
                            <div class="step-icon">2</div>
                            <h3 class="step-title">Paste it into your platform</h3>
                        </div>
                        <div class="step">
                            <div class="step-icon">3</div>
                            <h3 class="step-title">Start using</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: 'educationInstitutionsBanner',
    components: {},
    data() {

        return {
            htmlText: [
                { text: `<body> `, tab: 0 },
                {
                    text: `<div data-pym-src='https://www.jdoodle.com/plugin' data-language="java"
    data-version-index="4" data-libs="mavenlib1, mavenlib2"> `,
                    tab: 1
                },
                { text: `Optional default code goes here`, tab: 2 },
                { text: `</div>`, tab: 1 },

                { text: `<script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"/>`, tab: 1 },
                { text: `</body>`, tab: 0 },
            ]
        };
    },
    methods: {},
};
</script>

<style scoped>
.section-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.flex-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: -1rem;
    margin-right: -1rem;
    gap: 4rem;

    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
        gap: 0rem;
    }

}

.image-column {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 4rem;

    @media (min-width: 1024px) {
        width: 50%;
        margin-bottom: 0px;
    }
}

.image-container {
    width: 100%;
    height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;


    @media (min-width: 768px) {
        margin-left: 0px;
    }
}

.circle-image {
    position: absolute;
    z-index: 10;
    right: -2rem;
    top: -2rem;
    width: 7rem;
}

.dots-image {
    position: absolute;
    z-index: 10;
    left: -2.5rem;
    bottom: -2rem;
    width: 7rem;

}

.html-container {
    max-width: 80vw;
    width: 100%;

    min-height: fit-content;

    background-color: black;
    color: white;

    font-weight: 600;
    padding: 1rem;
    margin: 0;
    border-radius: 50px;


    @media (min-width: 1024px) {
        padding: 3rem;
    }
}

.menu {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-bottom: #e5ffea 2px solid;
}

.menu_Items {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}


.code-line {
    display: flex;
    align-items: left;
    text-align: left;
    margin-bottom: 1rem;
}

.tab {
    padding-right: 1rem;
    padding-top: 2rem;

    @media (min-width: 1024px) {
        padding-right: 2rem;
    }
}

.content-container {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: 1024px) {
        width: 50%;
    }
}

.badge {
    justify-self: self-start;
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    line-height: 1.25;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #e5ffea;
    color: #fd5a0d;
    border-radius: 9999px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.title {
    margin-bottom: 1rem;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 1.25;
    font-weight: 700;
    letter-spacing: -0.02px;

    @media (min-width: 768px) {
        font-size: 2.5rem;
    }

    @media (min-width: 1024px) {
        font-size: 3rem;
    }
}

.step-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    text-align: center;

    @media (min-width: 1024px) {

        text-align: left;
    }
}

.step {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;

    @media (min-width: 1024px) {

        width: 50%;
    }
}

.step-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    width: 3rem;
    height: 3rem;
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 9999px;

    color: #ffffff;
    background-color: #fd5a0d;

}

.step-title {
    margin-left: 1rem;
    font-size: 1.125rem;
    font-weight: bold;
}
</style>