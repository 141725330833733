<template>
    <section class="section-container">
        <div class="text-container">
            <span class="badge">Pricing</span>
            <h1 class="title">
                Free and custom pricing plan for your institution
            </h1>
            <p class="description">
                Pricing that meets your specific requirements.
            </p>
        </div>
        <div class="flex-container">
            <div class="column">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h3 class="card-title">Basic Plan</h3>
                            <p class="card-subtitle">Free with JDoodle branding</p>
                        </div>

                        <div>
                            <span class="currency">$</span>
                            <span class="amount">Free</span>
                            <span class="duration">/mo</span>
                        </div>
                    </div>
                    <div class="card-divider"></div>
                    <ul class="card-features">
                        <li class="feature"><img className="mr-3"
                                src="../../assets/images/educationInstitution/pricing/checkbox-green.svg" />
                            <span>Easy integration with 3 lines of HTML code
                            </span>
                        </li>
                        <li class="feature"><img className="mr-3"
                                src="../../assets/images/educationInstitution/pricing/checkbox-green.svg" />
                            <span>Complete Documentation</span>
                        </li>
                    </ul>
                    <div class="card-divider"></div>
                    <div class="card-button">
                        <button :disabled="$store.state.isUserLoggedIn" @click="onClickSignup" class="btn">Get Started
                            Now</button>

                    </div>
                </div>
            </div>
            <div class="column">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h3 class="card-title">Custom</h3>
                            <p class="card-subtitle">Unlimited possibilities</p>
                        </div>

                        <div>
                            <span class="currency">$</span>
                            <span class="amount">499</span>
                            <span class="duration">onwards/mo</span>
                        </div>
                    </div>
                    <div class="card-divider"></div>
                    <ul class="card-features">
                        <li class="feature"><img className="mr-3"
                                src="../../assets/images/educationInstitution/pricing/checkbox-green.svg" />
                            <span>Access to all features</span>
                        </li>
                        <li class="feature"><img className="mr-3"
                                src="../../assets/images/educationInstitution/pricing/checkbox-green.svg" />
                            <span>Assisted onboarding support</span>
                        </li>
                        <li class="feature"><img className="mr-3"
                                src="../../assets/images/educationInstitution/pricing/checkbox-green.svg" />
                            <span>White-labelled</span>
                        </li>
                        <li class="feature"><img className="mr-3"
                                src="../../assets/images/educationInstitution/pricing/checkbox-green.svg" />
                            <span>Tailor-made plugins to meet your requirements</span>
                        </li>
                    </ul>
                    <div class="card-divider"></div>
                    <div class="card-button">
                        <a class="btn" href="https://app.monstercampaigns.com/c/eelbkirqhtobo1lclzwx/"
                            rel="noopener noreferrer">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'
import { GURU_EVENTS } from '../../assets/javascript/constants'
export default {
    name: 'educationInstitutionsBanner',
    components: {},
    data() {
        return {};
    },
    methods: {
        onClickSignup() {
            eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../Register.vue'))
        }
    },
};
</script>

<style scoped>
.section-container {
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.text-container {
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @media (min-width: 1024px) {
        max-width: 56rem;
    }

}

.badge {
    display: inline-block;
    padding-left: 0.5rem
        /* 8px */
    ;
    padding-right: 0.5rem
        /* 8px */
    ;
    margin-bottom: 1rem;
    font-size: 0.75rem
        /* 12px */
    ;
    line-height: 1.25;
    text-transform: uppercase;
    font-weight: 600;
    background-color: #e5ffea;
    color: #fd5a0d;
    border-radius: 9999px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

.title {
    margin-bottom: 1rem;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 1.25;
    font-weight: 700;
    letter-spacing: -0.02px;

    @media (min-width: 1024px) {
        font-size: 2.25rem;
    }
}

.description {
    font-size: 1.125rem;
    color: #64748b;
    font-weight: 500;

    @media (min-width: 1024px) {
        font-size: 1.25rem;
    }
}

.flex-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 77.5%;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -1rem;
    margin-right: -1rem;

    @media (min-width: 1024px) {
        flex-direction: row;

    }
}

.column {
    width: 100%;
    height: 100%;
    padding: 1rem;

    @media (min-width: 1024px) {
        width: 50%;

    }
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    padding-bottom: 2rem;
    background-color: #f9fafb;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s;


}

.card:hover {
    transform: scale(1.05);
}

.card-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 1rem;

    @media (min-width: 1500px) {
        flex-direction: row;

    }
}

.card-title {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #374151;
    letter-spacing: -0.02em;

    @media (min-width: 1024px) {

        font-size: 1.875rem;

    }
}

.card-subtitle {

    color: #64748b;
    font-weight: 500;

}

.currency {
    position: relative;
    top: -2.5rem;
    right: 0.25rem;
    font-size: 1.875rem;
    font-weight: 600;
    color: #374151;
}

.amount {
    font-size: 3.75rem;
    font-weight: 600;
    color: #374151;
    letter-spacing: -0.02em;

    @media (min-width: 1024px) {
        font-size: 4rem;

    }
}

.duration {

    margin-left: 0.25rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #64748b;
}

.card-divider {
    border-bottom-width: 1px;
}

.card-features {
    align-self: flex-start;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.feature {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #64748b;
    font-weight: 500;
}

.feature img {
    margin-right: 0.75rem;
}

.card-button {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
}

.btn {
    display: inline-block;
    padding: 1rem 1.75rem;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    background-color: #fd5a0d;
    color: #ffffff;
    border: none;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: background-color 0.2s, box-shadow 0.2s;

    @media (min-width: 1024px) {
        font-size: 1.125rem;
    }
}

.btn:hover {
    background-color: #e14c07;
}

.btn:disabled {
    background-color: #64748b;
    color: #ffffff;
    box-shadow: none;
    pointer-events: none;
}
</style>
